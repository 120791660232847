<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_4">
      <img src="@/assets/images/m_header_logo.png" alt="" class="logo" />
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_cs_wrap">
      <h5>램 고객센터</h5>
      <h6>카테고리 일반</h6>
      <ul class="cs_tab">
        <li :class="{ on: $route.path.includes('notice') }">
          <router-link to="/cs/notice">공지사항</router-link>
        </li>
        <li :class="{ on: $route.path.includes('qna') }">
          <router-link to="/cs/qna">1:1 문의하기</router-link>
        </li>
        <li :class="{ on: $route.path.includes('faq') }">
          <router-link to="/cs/faq">FAQ</router-link>
        </li>
      </ul>
      <router-view ref="child_node" @moreCheck="moreCheck" />
    </div>
    <div class="rn_more" v-if="more">
      <router-link to="" @click.native="morePage"
        >{{ limit }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      more: false,
    };
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: true,
    });
  },
  mounted() {
    document.querySelector("html body").style.overflow = "";
  },
  computed: {
    ...mapState("cs", ["limit"]),
  },
  methods: {
    moreCheck(state) {
      this.more = state;
    },
    morePage() {
      this.$refs["child_node"].getList();
    },
  },
};
</script>

<style></style>
